import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Guide from './components/Guide';
import FAQ from './components/FAQ';
import About from './components/About';
import HebrewWithAI from './components/HebrewWithAI';
import NotFound from './components/NotFound';
import HolidayPage from './components/HolidayPage';
import { yearlyHolidayData } from './yearlyHolidayData'; // Import yearly holiday data
import { HDate } from '@hebcal/core';
import JewishHolidays from './components/JewishHolidays'; // Adjust import path as needed

const AppContent = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [conversionType, setConversionType] = useState('gregorianToHebrew');
  const [result, setResult] = useState('');
  const [isError, setIsError] = useState(false);

  const gregorianYearRef = useRef();
  const gregorianMonthRef = useRef();
  const gregorianDayRef = useRef();
  const hebrewYearRef = useRef();
  const hebrewMonthRef = useRef();
  const hebrewDayRef = useRef();

  const setDefaultValues = useCallback(() => {
    if (conversionType === 'gregorianToHebrew') {
      if (gregorianYearRef.current) gregorianYearRef.current.value = '2024';
      if (gregorianMonthRef.current) gregorianMonthRef.current.value = '07';
      if (gregorianDayRef.current) gregorianDayRef.current.value = '04';
    } else {
      if (hebrewYearRef.current) hebrewYearRef.current.value = '5784';
      if (hebrewMonthRef.current) hebrewMonthRef.current.value = 'Sivan';
      if (hebrewDayRef.current) hebrewDayRef.current.value = '28';
    }
  }, [conversionType]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDefaultValues();
    }, 0);
    return () => clearTimeout(timer);
  }, [setDefaultValues]);

  const handleConvert = async () => {
    setIsError(false);
    let response;
    let data;
    if (conversionType === 'gregorianToHebrew') {
      const gregorianDate = `${gregorianYearRef.current?.value}-${gregorianMonthRef.current?.value}-${gregorianDayRef.current?.value}`;
      response = await fetch(`/api/convert_to_hebrew?gregorian_date=${gregorianDate}`);
      data = await response.json();
      if (data.error) {
        setResult(`😟 ${data.error} 💔`);
        setIsError(true);
      } else {
        setResult(`🎉 ${data.hebrew_date} 🎉`);
      }
    } else {
      const hebrewDate = `${hebrewYearRef.current?.value}-${hebrewMonthRef.current?.value}-${hebrewDayRef.current?.value}`;
      response = await fetch(`/api/convert_to_gregorian?hebrew_date=${hebrewDate}`);
      data = await response.json();
      if (data.error) {
        setResult(`😟 ${data.error} 💔`);
        setIsError(true);
      } else {
        setResult(`🎉 ${data.gregorian_date} 🎉`);
      }
    }
  };

  const handleConversionTypeChange = (type) => {
    setConversionType(type);
    setResult('');
    setIsError(false);
    setDefaultValues();
  };

  const handleToday = () => {
    const today = new Date();
    if (conversionType === 'gregorianToHebrew') {
      if (gregorianYearRef.current) gregorianYearRef.current.value = today.getFullYear().toString();
      if (gregorianMonthRef.current) gregorianMonthRef.current.value = (today.getMonth() + 1).toString().padStart(2, '0');
      if (gregorianDayRef.current) gregorianDayRef.current.value = today.getDate().toString().padStart(2, '0');
    } else {
      const hebrewDate = new HDate();
      if (hebrewYearRef.current) hebrewYearRef.current.value = hebrewDate.getFullYear().toString();
      const hebrewMonthName = hebrewDate.getMonthName();
      const englishMonthNames = {
        'Nisan': 'Nisan', 'Iyyar': 'Iyar', 'Sivan': 'Sivan', 'Tamuz': 'Tammuz',
        'Av': 'Av', 'Elul': 'Elul', 'Tishrei': 'Tishrei', 'Cheshvan': 'Cheshvan',
        'Kislev': 'Kislev', 'Tevet': 'Tevet', 'Sh\'vat': 'Shevat', 'Adar': 'Adar',
        'Adar I': 'Adar I', 'Adar II': 'Adar II'
      };
      if (hebrewMonthRef.current) hebrewMonthRef.current.value = englishMonthNames[hebrewMonthName] || hebrewMonthName;
      if (hebrewDayRef.current) hebrewDayRef.current.value = hebrewDate.getDate().toString();
    }
  };

  const location = useLocation();

  // Automatically get supported years from yearlyHolidayData
  const supportedYears = Object.keys(yearlyHolidayData);

  const holidayPaths = Object.values(yearlyHolidayData).flatMap(yearData =>
    yearData.map(holiday => 
      `/holiday/${holiday.name.toLowerCase().replace(/\s+/g, '-')}-${holiday.year}`
    )
  );

  const knownPaths = [
    '/', 
    '/guide', 
    '/faq', 
    '/about', 
    '/hebrew-with-ai',
    ...supportedYears.map(year => `/jewish-holidays-${year}`),
    ...holidayPaths
  ];

  const isNotFound = !knownPaths.includes(location.pathname);

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900 flex flex-col justify-between">
      {!isNotFound && <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />}
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={
            <Home
              conversionType={conversionType}
              handleConversionTypeChange={handleConversionTypeChange}
              gregorianYearRef={gregorianYearRef}
              gregorianMonthRef={gregorianMonthRef}
              gregorianDayRef={gregorianDayRef}
              hebrewYearRef={hebrewYearRef}
              hebrewMonthRef={hebrewMonthRef}
              hebrewDayRef={hebrewDayRef}
              handleConvert={handleConvert}
              handleToday={handleToday}
              result={result}
              isError={isError}
            />
          } />
          <Route path="/guide" element={<Guide />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/about" element={<About />} />
          <Route path="/hebrew-with-ai" element={<HebrewWithAI />} />
          {supportedYears.map(year => (
            <Route 
              key={year}
              path={`/jewish-holidays-${year}`}
              element={<JewishHolidays year={year} />}
            />
          ))}
          {Object.keys(yearlyHolidayData).flatMap(year => 
            yearlyHolidayData[year].map((holiday, index) => (
              <Route
                key={`${year}-${index}`}
                path={`/holiday/${holiday.name.toLowerCase().replace(/\s+/g, '-')}-${year}`}
                element={<HolidayPage holidayName={holiday.name} year={year} supportedYears={supportedYears} />}
              />
            ))
          )}
          <Route path="/sitemap.xml" element={<></>} />
          <Route path="/robots.txt" element={<></>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      {!isNotFound && <Footer />}
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;

