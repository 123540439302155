// baseHolidayData.js

export const baseHolidayData = [
    {
        name: "Tu B'Shevat",
        history: "Tu B'Shevat, also known as the New Year for Trees, has its origins in the Mishnaic period. It marks the season in which the earliest-blooming trees in the Land of Israel emerge from their winter sleep and begin a new fruit-bearing cycle. Historically, it was designated as the cutoff date for calculating the age of fruit trees for tithing purposes.",
        religiousSignificance: "Tu B'Shevat is rooted in the agricultural laws of the Torah and is one of the four New Years mentioned in the Mishna. In Kabbalistic tradition, it is considered a day to connect to the spiritual essence of nature and the Tree of Life.",
        culturalFamily: "Families often celebrate by planting trees and participating in environmental activities. In Israel, it is common to see tree-planting ceremonies and ecological initiatives during this time.",
        foodCustoms: "It is customary to eat a variety of fruits, particularly those that are symbolic to the land of Israel, such as figs, dates, pomegranates, olives, and grapes. A special 'Tu B'Shevat Seder' has been developed in which participants eat fruits and nuts, drink wine, and recite blessings and readings.",
        educationalImportance: "Tu B'Shevat provides an opportunity to teach about environmental stewardship, sustainability, and the significance of the natural world in Jewish tradition. Schools and educational programs often incorporate activities related to ecology and conservation.",
        socialInteraction: "Community events, such as tree planting and nature hikes, are common during Tu B'Shevat. These activities foster a sense of communal responsibility towards the environment.",
        spiritualReflection: "Tu B'Shevat encourages reflection on personal growth and renewal. Just as trees renew themselves, individuals are encouraged to consider their own spiritual development and their connection to the natural world."
    },
    {
        name: "Purim",
        history: "Purim commemorates the deliverance of the Jewish people in the ancient Persian Empire from a plot to annihilate them, as recorded in the Book of Esther. The wicked Haman had planned to destroy the Jews, but his plans were foiled by Queen Esther and her cousin Mordechai.",
        religiousSignificance: "Purim is a joyous festival marked by the reading of the Megillah (Book of Esther), which recounts the story of the Jewish people's salvation. It is a time to celebrate divine intervention and the triumph of good over evil.",
        culturalFamily: "Families celebrate Purim by dressing up in costumes, which can represent characters from the Purim story or other creative choices. Children often participate in costume parades and plays depicting the story of Esther.",
        foodCustoms: "Traditional foods include hamantaschen, triangular pastries filled with poppy seeds, jam, or other sweet fillings, symbolizing Haman's hat or ears. A festive meal is held, often accompanied by wine and spirits, in keeping with the celebratory nature of the day.",
        educationalImportance: "Purim is an opportunity to teach about Jewish survival, bravery, and the importance of standing up against injustice. Schools often hold Purim carnivals and educational activities related to the holiday's themes.",
        socialInteraction: "Purim is marked by public readings of the Megillah, giving charity to the poor (matanot la'evyonim), and exchanging gifts of food (mishloach manot) with friends and family. These customs foster a sense of community and generosity.",
        spiritualReflection: "Purim encourages reflection on the hidden miracles in our lives, as the events of the Purim story are seen as a series of divinely orchestrated coincidences. It is a time to recognize the unseen hand of God in the unfolding of history."
    },
    {
        name: "Passover",
        history: "Passover, or Pesach, commemorates the Exodus of the Israelites from slavery in Egypt, as described in the Torah. It is one of the most significant holidays in the Jewish calendar, celebrating the deliverance of the Israelites and their journey to freedom.",
        religiousSignificance: "Passover is observed with the Seder meal, during which the story of the Exodus is recounted through readings, prayers, and symbolic foods. The central theme is liberation and the enduring spirit of freedom.",
        culturalFamily: "Families gather for the Seder, which includes the reading of the Haggadah, eating matzah, and engaging in rituals that symbolize the hardships and joys of the Israelites' journey. It is a time for family bonding and storytelling.",
        foodCustoms: "During Passover, Jews eat unleavened bread (matzah) and avoid chametz (leavened products). The Seder meal includes symbolic foods such as bitter herbs, charoset (a sweet paste representing the mortar used by the Israelite slaves), and a roasted lamb shank bone.",
        educationalImportance: "Passover provides a rich educational experience, teaching about freedom, oppression, and the importance of remembrance. It emphasizes the value of questioning and seeking understanding, as epitomized by the tradition of the Four Questions asked by the youngest child at the Seder.",
        socialInteraction: "Passover Seders often include extended family and friends, creating a communal atmosphere of celebration and remembrance. It is also customary to invite guests, especially those who might not have a Seder to attend.",
        spiritualReflection: "Passover invites reflection on themes of redemption, faith, and the enduring relevance of the Exodus story. It is a time to consider the personal and collective journeys from bondage to freedom."
    },
    {
        name: "Yom HaShoah",
        history: "Yom HaShoah, Holocaust Remembrance Day, was established by the Israeli Knesset in 1951 to commemorate the six million Jews who perished in the Holocaust during World War II. It serves as a day of remembrance and education about the atrocities committed.",
        religiousSignificance: "Yom HaShoah is a solemn day of mourning for the victims of the Holocaust. It is marked by ceremonies, moments of silence, and the lighting of memorial candles to honor those who lost their lives.",
        culturalFamily: "Families may participate in memorial services, visit Holocaust memorials and museums, and engage in discussions about the impact of the Holocaust. It is a time for intergenerational dialogue and education.",
        foodCustoms: "There are no specific food customs associated with Yom HaShoah; it is a day focused on remembrance and reflection.",
        educationalImportance: "Yom HaShoah emphasizes the importance of Holocaust education, preserving the memory of the victims, and teaching the lessons of tolerance and human rights. Educational programs often include survivor testimonies, historical studies, and discussions on preventing future genocides.",
        socialInteraction: "Communities gather for memorial services, speeches, and moments of silence to honor the memory of the Holocaust victims. These events foster a sense of collective memory and responsibility.",
        spiritualReflection: "Yom HaShoah encourages reflection on the themes of loss, resilience, and the imperative to remember and prevent future atrocities. It is a time to reaffirm the commitment to never forget and to stand against hatred and bigotry."
    },
    {
        name: "Yom HaZikaron",
        history: "Yom HaZikaron, Israel's Memorial Day, was established to honor the soldiers who have fallen in defense of Israel and victims of terrorism. It is a day of national mourning and remembrance for those who sacrificed their lives for the state's security.",
        religiousSignificance: "Yom HaZikaron is a day of reflection on the cost of Israel's independence and security. It is marked by solemn ceremonies, prayers, and moments of silence.",
        culturalFamily: "Families of fallen soldiers may visit cemeteries, attend memorial services, and participate in national commemorations. It is a day for personal and collective mourning.",
        foodCustoms: "There are no specific food customs associated with Yom HaZikaron; it is a day of mourning and reflection.",
        educationalImportance: "Yom HaZikaron highlights the importance of national service, sacrifice, and the personal stories of those who have died in defense of Israel. Schools and educational institutions hold programs to teach about the significance of the day.",
        socialInteraction: "Communities come together for memorial services, moments of silence, and ceremonies to honor the fallen. These events foster a sense of national unity and collective memory.",
        spiritualReflection: "Yom HaZikaron prompts reflection on themes of sacrifice, national identity, and the cost of freedom and security. It is a time to honor the memory of the fallen and to support their families."
    },
    {
        name: "Yom HaAtzmaut",
        history: "Yom HaAtzmaut, Israel's Independence Day, marks the declaration of the establishment of the State of Israel in 1948. It is a day of national celebration commemorating the founding of the modern Jewish state.",
        religiousSignificance: "Yom HaAtzmaut is a day of thanksgiving and celebration for the achievements and existence of the State of Israel. It is often marked by prayers of gratitude and special services.",
        culturalFamily: "Families celebrate with festive meals, barbecues, parades, and fireworks. Outdoor activities such as picnics and hikes are also common as people enjoy the holiday with loved ones.",
        foodCustoms: "Traditional foods include Israeli cuisine such as falafel, hummus, and other dishes enjoyed at community celebrations. Many families have barbecues and outdoor feasts.",
        educationalImportance: "Yom HaAtzmaut is an opportunity to teach about the history of Israel's founding, its cultural diversity, and its significance to the Jewish people. Educational programs often include lessons on Israel's history, achievements, and challenges.",
        socialInteraction: "Communities come together for celebrations, cultural performances, and national ceremonies. These events foster a sense of pride, unity, and connection to the state of Israel.",
        spiritualReflection: "Yom HaAtzmaut invites reflection on the journey of the Jewish people to statehood, the challenges faced, and the hopes for the future. It is a time to celebrate freedom, resilience, and the ongoing effort to build a just and thriving society."
    },
    {
        name: "Lag B'Omer",
        history: "Lag B'Omer, the 33rd day of the Omer count, commemorates the end of a plague that afflicted Rabbi Akiva's students in the 2nd century. It is also associated with the anniversary of the death of Rabbi Shimon bar Yochai, a revered sage and mystic.",
        religiousSignificance: "Lag B'Omer is a day of celebration and joy amidst the semi-mourning period of the Omer. It marks a break from the mourning customs, and weddings and haircuts, which are otherwise prohibited during the Omer, are permitted.",
        culturalFamily: "Families celebrate with outdoor activities, picnics, and bonfires. In Israel, it is traditional to visit the tomb of Rabbi Shimon bar Yochai in Meron, where large gatherings and festivities take place.",
        foodCustoms: "There are no specific traditional foods for Lag B'Omer, but picnics and barbecues are common as people spend the day outdoors.",
        educationalImportance: "Lag B'Omer provides an opportunity to teach about Jewish history, the significance of the Omer period, and the contributions of Rabbi Shimon bar Yochai. Educational activities may include studying Jewish texts and learning about the mystical traditions associated with the day.",
        socialInteraction: "Communities often organize outdoor events, sports activities, and communal bonfires. These activities foster a sense of community and joy.",
        spiritualReflection: "Lag B'Omer invites reflection on themes of resilience, the end of hardship, and the importance of unity and community. It is a time to celebrate life and the contributions of Jewish scholars and mystics."
    },
    {
        name: "Shavuot",
        history: "Shavuot, also known as the Feast of Weeks, commemorates the giving of the Torah to the Israelites at Mount Sinai. It marks the conclusion of the seven-week counting period between Passover and Shavuot.",
        religiousSignificance: "Shavuot is one of the Shalosh Regalim, the three pilgrimage festivals. It is a time to celebrate the revelation of the Torah and the covenant between God and the Jewish people.",
        culturalFamily: "Families celebrate Shavuot by decorating their homes with greenery, attending synagogue services, and participating in all-night Torah study sessions known as Tikkun Leil Shavuot.",
        foodCustoms: "It is customary to eat dairy foods on Shavuot, such as cheesecake, blintzes, and cheese-filled pastries. This tradition has various explanations, including the idea that the Israelites did not have time to prepare meat after receiving the Torah.",
        educationalImportance: "Shavuot emphasizes the importance of Torah study, education, and the transmission of Jewish values. Educational programs may include studying the Ten Commandments and other significant texts.",
        socialInteraction: "Shavuot is marked by communal prayers, festive meals, and educational gatherings. These activities strengthen communal bonds and celebrate the collective acceptance of the Torah.",
        spiritualReflection: "Shavuot invites reflection on the significance of the Torah, the responsibilities of the covenant, and the importance of continual learning and growth in one's spiritual journey."
    },
    {
        name: "Tisha B'Av",
        history: "Tisha B'Av, the Ninth of Av, is a day of mourning commemorating the destruction of the First and Second Temples in Jerusalem, as well as other tragedies that have befallen the Jewish people.",
        religiousSignificance: "Tisha B'Av is observed with fasting, prayer, and reading the Book of Lamentations (Eicha). It is a time for reflection on the suffering and destruction experienced by the Jewish people throughout history.",
        culturalFamily: "Families and communities gather in synagogues to read the Book of Lamentations and participate in services that reflect the somber nature of the day.",
        foodCustoms: "Tisha B'Av is a fast day, and no food or drink is consumed from sunset on the eve of Tisha B'Av until nightfall the following day. The meal before the fast is typically simple, often consisting of bread, eggs, and water.",
        educationalImportance: "Tisha B'Av provides an opportunity to teach about Jewish history, the significance of the Temples, and the themes of loss and resilience. Educational programs often include studying historical events and their impact on the Jewish people.",
        socialInteraction: "The communal aspect of Tisha B'Av services fosters a sense of shared mourning and reflection. It is a time for the community to come together in grief and hope.",
        spiritualReflection: "Tisha B'Av invites reflection on themes of destruction, exile, and the hope for redemption. It is a time to contemplate the causes of these tragedies and to seek ways to improve oneself and the community."
    },
    {
        name: "Rosh Hashanah",
        history: "Rosh Hashanah, the Jewish New Year, marks the beginning of the High Holy Days. It is believed to be the anniversary of the creation of Adam and Eve and serves as a time for introspection and judgment.",
        religiousSignificance: "Rosh Hashanah is a solemn yet joyous occasion marked by prayer, the sounding of the shofar (ram's horn), and reflection on the past year. It is a time to seek forgiveness and make resolutions for the coming year.",
        culturalFamily: "Families celebrate with festive meals, including symbolic foods such as apples dipped in honey for a sweet new year, pomegranates representing abundance, and round challah bread symbolizing the cycle of the year.",
        foodCustoms: "Traditional foods for Rosh Hashanah include apples dipped in honey, pomegranates, fish heads (to be 'like the head and not the tail'), and round challah bread. Many families also enjoy a variety of festive dishes.",
        educationalImportance: "Rosh Hashanah provides an opportunity to teach about Jewish traditions, the themes of repentance and renewal, and the significance of the High Holy Days. Educational programs often include learning about the shofar and its meanings.",
        socialInteraction: "Rosh Hashanah is marked by synagogue services, family gatherings, and communal meals. It is a time for communal prayer, reflection, and celebration.",
        spiritualReflection: "Rosh Hashanah invites reflection on one's actions over the past year, the importance of repentance, and the desire for a good and sweet year ahead. It is a time for personal and spiritual growth."
    },
    {
        name: "Yom Kippur",
        history: "Yom Kippur, the Day of Atonement, is the holiest day in the Jewish calendar. It is a day dedicated to repentance, prayer, and fasting, marking the culmination of the ten Days of Awe that begin with Rosh Hashanah.",
        religiousSignificance: "Yom Kippur is observed with a 25-hour fast, intensive prayer, and confession of sins (vidui). It is a day to seek atonement and forgiveness from God and to make amends with others.",
        culturalFamily: "Families come together for the pre-fast meal (seuda mafseket) and break the fast together at the end of the day. The day is spent in synagogue, participating in lengthy prayer services.",
        foodCustoms: "The meal before the fast is typically hearty and includes foods that provide sustenance and hydration. The break-fast meal often includes light foods such as dairy products, bread, and sweets.",
        educationalImportance: "Yom Kippur emphasizes the importance of repentance, forgiveness, and self-examination. Educational programs may include learning about the rituals and prayers of the day and the significance of atonement.",
        socialInteraction: "Yom Kippur is marked by communal prayer and fasting. The collective experience of the fast and the prayers strengthens communal bonds and the shared sense of purpose.",
        spiritualReflection: "Yom Kippur invites deep reflection on one's actions, the process of repentance, and the desire to improve oneself. It is a time to seek reconciliation with God and others, and to commit to positive change in the coming year."
    },
    {
        name: "Sukkot",
        history: "Sukkot, the Feast of Tabernacles, commemorates the 40 years the Israelites spent wandering in the desert after the Exodus from Egypt. It is a time to remember God's protection and provision during their journey.",
        religiousSignificance: "Sukkot is observed by dwelling in temporary structures called sukkot (singular: sukkah), which symbolize the fragile dwellings the Israelites lived in during their desert wanderings. It is a time to express gratitude for the harvest and God's blessings.",
        culturalFamily: "Families build and decorate a sukkah, where they eat meals and sometimes sleep during the holiday. The sukkah is often adorned with fruits, vegetables, and other decorations.",
        foodCustoms: "Meals are eaten in the sukkah, and traditional foods include stuffed vegetables and fruits, symbolizing the harvest. It is a time to enjoy seasonal produce and festive dishes.",
        educationalImportance: "Sukkot provides an opportunity to teach about Jewish history, the significance of the Exodus, and the importance of gratitude and trust in God. Educational programs may include learning about the Four Species (lulav, etrog, hadassim, and aravot) and their meanings.",
        socialInteraction: "Sukkot is marked by communal meals, hospitality, and inviting guests (ushpizin) into the sukkah. It is a time for community gatherings and celebrations.",
        spiritualReflection: "Sukkot invites reflection on the themes of impermanence, reliance on God, and the importance of gratitude. It is a time to rejoice in the blessings of life and to deepen one's faith."
    },
    {
        name: "Shemini Atzeret",
        history: "Shemini Atzeret, the 'Eighth Day of Assembly,' follows the seven days of Sukkot. It is considered a separate, yet connected, holiday to Sukkot, marking the conclusion of the festival period.",
        religiousSignificance: "Shemini Atzeret is a time of solemn assembly and prayer for rain (Geshem), marking the beginning of the rainy season in Israel. It is also a time to celebrate the conclusion of the annual Torah reading cycle.",
        culturalFamily: "Families gather for festive meals and attend synagogue services, which include special prayers for rain and the recitation of Yizkor, a memorial prayer for deceased relatives.",
        foodCustoms: "Traditional foods for Shemini Atzeret include festive meals similar to those of Sukkot, often with a focus on seasonal and harvest-related dishes.",
        educationalImportance: "Shemini Atzeret emphasizes the themes of unity, prayer, and the importance of rain for the land of Israel. Educational programs may include learning about the agricultural cycle and the significance of water in Jewish tradition.",
        socialInteraction: "Shemini Atzeret is marked by communal prayers, festive meals, and gatherings in the sukkah. It is a time for communal worship and reflection.",
        spiritualReflection: "Shemini Atzeret invites reflection on the themes of conclusion and renewal, the importance of prayer for sustenance, and the continuity of the Torah reading cycle. It is a time to seek God's blessings for the coming year."
    },
    {
        name: "Simchat Torah",
        history: "Simchat Torah, 'Rejoicing of the Torah,' celebrates the conclusion of the annual cycle of Torah readings and the beginning of a new cycle. It is a joyous holiday that follows Shemini Atzeret.",
        religiousSignificance: "Simchat Torah is marked by the completion of the Torah reading and the immediate start of a new cycle, symbolizing the continuous nature of Torah study. It is a time to celebrate the Torah and its centrality in Jewish life.",
        culturalFamily: "Families participate in synagogue celebrations, which include dancing with the Torah scrolls (hakafot), singing, and joyful festivities. Children are often given flags and treats to join in the celebration.",
        foodCustoms: "Festive meals are enjoyed, often featuring sweet foods to symbolize the sweetness of the Torah. Traditional dishes vary but generally include celebratory and indulgent foods.",
        educationalImportance: "Simchat Torah provides an opportunity to teach about the importance of Torah study, the joy of learning, and the significance of the Torah in Jewish tradition. Educational programs may include studying the concluding and beginning verses of the Torah.",
        socialInteraction: "Simchat Torah is marked by communal celebrations, dancing, and singing. It is a time for the entire community to come together in joyous celebration and to express their love for the Torah.",
        spiritualReflection: "Simchat Torah invites reflection on the continuous nature of learning and growth. It is a time to rejoice in the gift of the Torah and to commit to ongoing study and spiritual development."
    },
    {
        name: "Hanukkah",
        history: "Hanukkah, the Festival of Lights, commemorates the rededication of the Second Temple in Jerusalem and the miracle of the oil that burned for eight days when there was only enough oil for one day.",
        religiousSignificance: "Hanukkah is a time to celebrate religious freedom and the miracles that have sustained the Jewish people. It is marked by the lighting of the menorah (hanukkiah), with one additional candle lit on each of the eight nights.",
        culturalFamily: "Families celebrate Hanukkah by lighting the menorah, reciting blessings, singing songs, playing games (such as dreidel), and exchanging gifts. It is a time for family gatherings and festive celebrations.",
        foodCustoms: "Traditional foods for Hanukkah include fried foods such as latkes (potato pancakes) and sufganiyot (jelly-filled doughnuts), symbolizing the miracle of the oil. Dairy foods are also enjoyed, commemorating the bravery of Judith.",
        educationalImportance: "Hanukkah provides an opportunity to teach about Jewish history, the significance of religious freedom, and the miracles associated with the holiday. Educational programs may include learning about the Maccabees and the historical context of the holiday.",
        socialInteraction: "Hanukkah is marked by communal celebrations, menorah lightings, and parties. These activities foster a sense of community and joy.",
        spiritualReflection: "Hanukkah invites reflection on the themes of light, miracles, and the enduring strength of the Jewish spirit. It is a time to celebrate the triumph of light over darkness and to appreciate the miracles in our own lives."
    }
];
