// JewishHolidays.js

import React, { useState } from 'react';
import { Calendar, Info, ChevronRight, ChevronLeft } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { yearlyHolidayData } from '../yearlyHolidayData';  // Import yearly holiday data using correct path
import { baseHolidayData } from '../baseHolidayData';  // Import base holiday data using correct path

const JewishHolidays = ({ year }) => {
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState(false);

  const holidays = yearlyHolidayData[year];

  if (!holidays) {
    return <div>Error: No holiday data found for year {year}</div>;
  }

  const combinedData = holidays.map(holiday => {
    const baseData = baseHolidayData.find(bh => bh.name === holiday.name);
    return { ...holiday, ...baseData };
  });

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  const supportedYears = Object.keys(yearlyHolidayData);
  const currentYearIndex = supportedYears.indexOf(year);
  const prevYear = currentYearIndex > 0 ? supportedYears[currentYearIndex - 1] : null;
  const nextYear = currentYearIndex < supportedYears.length - 1 ? supportedYears[currentYearIndex + 1] : null;

  return (
    <>
      <Helmet>
        <title>{year} Jewish Holidays</title>
        <meta name="description" content={`A comprehensive list of Jewish holidays for the year ${year}, including Tu B'Shevat, Purim, Passover, and more.`} />
        <meta name="keywords" content={`Jewish holidays ${year}, Hebrew holidays ${year}, Tu B'Shevat, Purim, Passover, Yom HaShoah, Yom HaZikaron, Yom HaAtzmaut, Lag B'Omer, Shavuot, Tisha B'Av, Rosh Hashanah, Yom Kippur, Sukkot, Shemini Atzeret, Simchat Torah, Hanukkah`} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${year} Jewish Holidays",
              "description": "A comprehensive list of Jewish holidays for the year ${year}"
            }
          `}
        </script>
      </Helmet>
      <div className="container mx-auto px-4 py-8 md:py-16">
        <div className="flex justify-between items-center mb-8">
          <div>
            {prevYear && (
              <button
                onClick={() => navigate(`/jewish-holidays-${prevYear}`)}
                className="text-blue-600 hover:text-blue-800 flex items-center"
                title="Previous Year"
              >
                <ChevronLeft size={24} />
                {prevYear}
              </button>
            )}
          </div>
          <h1 className="text-3xl md:text-5xl font-bold text-center">{year} Jewish Holidays</h1>
          <div>
            {nextYear && (
              <button
                onClick={() => navigate(`/jewish-holidays-${nextYear}`)}
                className="text-blue-600 hover:text-blue-800 flex items-center"
                title="Next Year"
              >
                {nextYear}
                <ChevronRight size={24} />
              </button>
            )}
          </div>
        </div>
        <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
          <div className="mb-6 bg-blue-50 rounded-lg p-4 border border-blue-200">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-xl font-semibold text-blue-800">Holiday List</h2>
              <button
                onClick={toggleInfo}
                className="text-blue-600 hover:text-blue-800"
                title="Show/Hide Information"
              >
                <Info size={24} />
              </button>
            </div>
            {showInfo && (
              <div className="mt-2 p-3 bg-white rounded-md text-sm text-gray-600 border border-blue-200">
                <p>Jewish holidays begin at sundown on the evening before the date listed and end at nightfall on the date given. The Hebrew calendar is lunisolar, so dates may vary slightly from year to year in the Gregorian calendar.</p>
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {combinedData.map((holiday, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-4 border border-gray-200 relative">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">{holiday.name}</h3>
                <div className="flex items-center text-gray-600 mb-2">
                  <Calendar size={18} className="mr-2" />
                  <span>{holiday.date}</span>
                </div>
                <div className="text-sm text-gray-500 mb-2">
                  <span>Hebrew Date: {holiday.hebrewDate}</span>
                </div>
                <Link 
                  to={`/holiday/${holiday.name.toLowerCase().replace(/\s+/g, '-')}-${year}`}
                  className="absolute top-2 right-2 text-blue-600 hover:text-blue-800"
                  title="Click for more details"
                >
                  <ChevronRight size={18} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default JewishHolidays;

