import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const ExpandableSection = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="mb-6">
      <button
        className="flex justify-between items-center w-full text-left text-base md:text-lg font-semibold mb-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="pr-4">{title}</span>
        {isExpanded ? <Minus className="h-5 w-5 flex-shrink-0" /> : <Plus className="h-5 w-5 flex-shrink-0" />}
      </button>
      {isExpanded && <div className="mt-2 text-sm md:text-base">{children}</div>}
    </div>
  );
};

const Guide = () => (
  <section className="container mx-auto px-4 py-8 md:py-16">
    <h2 className="text-xl md:text-3xl font-bold text-center mb-8">
      Guide to <span className="text-blue-600">Calendar Conversion and Hebrew with AI</span>
    </h2>
    <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-4 md:p-6">
      <ExpandableSection title="How to Use the Standard Converter">
        <ol className="list-decimal list-inside space-y-2">
          <li>Select the conversion type (Gregorian to Hebrew or vice versa).</li>
          <li>Enter the date in the appropriate format.</li>
          <li>Click the "Convert" button to see the result.</li>
          <li>The converted date will appear in the result field.</li>
        </ol>
        <p className="mt-2">For Gregorian dates, use the format YYYY-MM-DD. For Hebrew dates, select the month from the dropdown and enter the year and day.</p>
      </ExpandableSection>
      
      <ExpandableSection title="Using Hebrew with AI">
        <ol className="list-decimal list-inside space-y-2">
          <li>Navigate to the "Hebrew with AI" section.</li>
          <li>Choose between Gregorian to Hebrew or Hebrew to Gregorian conversion.</li>
          <li>Enter text containing dates in various formats and languages.</li>
          <li>Click the "Convert" button to process the text.</li>
          <li>The AI will identify and convert all relevant dates based on your selection.</li>
          <li>Review the converted text in the result area.</li>
        </ol>
        <p className="mt-2">The AI, powered by large language models, can handle multiple date formats and languages, including English, Hebrew, French, Spanish, German, Chinese, Japanese, Korean, and Arabic. It understands context and can process complex historical and cultural date references.</p>
      </ExpandableSection>
      
      <ExpandableSection title="Understanding Calendar Differences">
        <p className="mb-2">
          The Hebrew calendar is a lunisolar calendar, while the Gregorian calendar is a solar calendar. 
          This means that dates don't always align perfectly between the two systems.
        </p>
        <p>Key differences include:</p>
        <ul className="list-disc list-inside mt-2">
          <li>The Hebrew calendar's new year starts in autumn (Tishrei), not winter.</li>
          <li>Hebrew months are based on the lunar cycle and can have 29 or 30 days.</li>
          <li>The Hebrew calendar adds an extra month (Adar II) in leap years to stay aligned with the solar year.</li>
        </ul>
        <p className="mt-2">
          Our converter and AI system take into account these differences to provide accurate conversions.
        </p>
      </ExpandableSection>

      <ExpandableSection title="Tips for Accurate Conversions">
        <ul className="list-disc list-inside space-y-2">
          <li>For historical dates, be aware of calendar reforms and local adoption dates of the Gregorian calendar.</li>
          <li>When using Hebrew with AI, provide context around the dates for more accurate interpretations.</li>
          <li>For religious or legal purposes, always verify converted dates with an authoritative source.</li>
          <li>Remember that Hebrew dates begin at sunset of the previous day.</li>
        </ul>
      </ExpandableSection>
    </div>
  </section>
);

export default Guide;
