import React from 'react';

const About = () => (
  <main className="container mx-auto px-4 py-8 md:py-16">
    <h2 className="text-xl md:text-3xl font-bold text-center mb-8">
      About <span className="text-blue-600">Our Project</span>
    </h2>
    <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
      <h3 className="text-xl md:text-2xl font-semibold mb-4">Our Mission</h3>
      <p className="mb-6 text-sm md:text-base">
        We aim to provide an accurate and easy-to-use tool for converting between 
        Hebrew and Gregorian calendars, bridging cultural and historical date systems. 
        With our new Hebrew with AI feature, powered by advanced large language models (LLMs), 
        we're taking this mission a step further by offering intelligent date conversion within text.
      </p>
      
      <h3 className="text-xl md:text-2xl font-semibold mb-4">Our Features</h3>
      <ul className="list-disc list-inside mb-6 text-sm md:text-base">
        <li>Precise Hebrew-Gregorian date conversion</li>
        <li>User-friendly interface for single date conversion</li>
        <li>Hebrew with AI: LLM-powered intelligent date conversion in text</li>
        <li>Support for historical dates and multiple languages</li>
      </ul>
      
      <h3 className="text-xl md:text-2xl font-semibold mb-4">Hebrew with AI</h3>
      <p className="mb-6 text-sm md:text-base">
        Our Hebrew with AI feature leverages state-of-the-art large language models to identify 
        and convert dates within text. This advanced AI technology allows for:
      </p>
      <ul className="list-disc list-inside mb-6 text-sm md:text-base">
        <li>Understanding context and nuances in date references</li>
        <li>Processing multiple languages and various date formats</li>
        <li>Handling complex historical and cultural date references</li>
        <li>Providing explanations for date conversions when needed</li>
      </ul>
      <p className="mb-6 text-sm md:text-base">
        This makes it perfect for working with historical documents, planning events, 
        or any text containing dates that need conversion between Hebrew and Gregorian calendars.
      </p>

      <h3 className="text-xl md:text-2xl font-semibold mb-4">The Team</h3>
      <p className="mb-6 text-sm md:text-base">
        Our team consists of developers, historians, AI specialists, and calendar enthusiasts 
        dedicated to creating the most reliable and innovative calendar conversion tools available.
      </p>

      <h3 className="text-xl md:text-2xl font-semibold mb-4">Contact Us</h3>
      <p className="text-sm md:text-base">
        If you have any questions, suggestions, or feedback about our calendar converter 
        or the LLM-powered Hebrew with AI feature, please don't hesitate to reach out to us at 
        <a href="mailto:contact@hebrew-calendar.com" className="text-blue-600 hover:underline"> contact@hebrew-calendar.com</a>.
      </p>
    </div>
  </main>
);

export default About;
