// yearlyHolidayData.js

export const yearlyHolidayData = {
    "2023": [
        {
            name: "Tu B'Shevat",
            date: "February 6, 2023",
            hebrewDate: "15 Shevat 5783",
            year: 2023,
            message: "Tu B'Shevat invites us to connect with the divine life force within nature. As we celebrate the New Year for Trees, let us reflect on the growth and renewal that is possible in our own lives, and embrace the joy of creation."
        },
        {
            name: "Purim",
            date: "March 6-7, 2023",
            hebrewDate: "14-15 Adar 5783",
            year: 2023,
            message: "Purim is a time to recognize the hidden miracles in our lives. The story of Esther and Mordechai teaches us that even in the darkest times, there is hope and redemption. Let us celebrate with joy, laughter, and gratitude for the unseen blessings."
        },
        {
            name: "Passover",
            date: "April 5-13, 2023",
            hebrewDate: "15-22 Nisan 5783",
            year: 2023,
            message: "Passover is a journey from bondage to freedom, a time to cleanse our souls and renew our spirits. As we gather for the Seder, we are reminded of the power of faith and the promise of redemption that Hashem provides."
        },
        {
            name: "Yom HaShoah",
            date: "April 18, 2023",
            hebrewDate: "27 Nisan 5783",
            year: 2023,
            message: "Yom HaShoah is a day to remember the six million souls lost in the Holocaust. It is a time for deep reflection and commitment to ensuring that such darkness never overshadows our world again. Through remembrance, we seek to build a future of light and peace."
        },
        {
            name: "Yom HaZikaron",
            date: "April 25, 2023",
            hebrewDate: "4 Iyar 5783",
            year: 2023,
            message: "On Yom HaZikaron, we honor the bravery and sacrifice of those who gave their lives for Israel. Their dedication inspires us to uphold the values of justice and peace, and to cherish the freedom they have secured for us."
        },
        {
            name: "Yom HaAtzmaut",
            date: "April 26, 2023",
            hebrewDate: "5 Iyar 5783",
            year: 2023,
            message: "Yom HaAtzmaut is a celebration of the miracle of Israel's independence. It is a time to rejoice in our nation's achievements and to commit to building a just and compassionate society, grounded in the values of our faith."
        },
        {
            name: "Lag B'Omer",
            date: "May 9, 2023",
            hebrewDate: "18 Iyar 5783",
            year: 2023,
            message: "Lag B'Omer is a day of joy and spiritual illumination, marking the end of a period of mourning. It celebrates the life and teachings of Rabbi Shimon bar Yochai, reminding us of the light and wisdom that Torah brings into our lives."
        },
        {
            name: "Shavuot",
            date: "May 26-27, 2023",
            hebrewDate: "6-7 Sivan 5783",
            year: 2023,
            message: "Shavuot is a time to receive the Torah anew, with all its wisdom and guidance. As we celebrate the giving of the Torah at Mount Sinai, we are reminded of our covenant with Hashem and our commitment to living a life of holiness and righteousness."
        },
        {
            name: "Tisha B'Av",
            date: "July 27, 2023",
            hebrewDate: "9 Av 5783",
            year: 2023,
            message: "Tisha B'Av is a day of mourning for the destruction of the Holy Temples and other tragedies. It is a time for introspection, repentance, and the hope for the rebuilding of Jerusalem and the restoration of peace in our world."
        },
        {
            name: "Rosh Hashanah",
            date: "September 16-17, 2023",
            hebrewDate: "1-2 Tishrei 5784",
            year: 2023,
            message: "Rosh Hashanah, the Jewish New Year, is a time of reflection and renewal. As we hear the shofar's call, we are reminded of our potential for growth and positive change, and we commit to living a life aligned with our highest values."
        },
        {
            name: "Yom Kippur",
            date: "September 25, 2023",
            hebrewDate: "10 Tishrei 5784",
            year: 2023,
            message: "Yom Kippur, the Day of Atonement, is a time for deep introspection and spiritual cleansing. Through fasting and prayer, we seek to reconcile with Hashem and our fellow human beings, striving to improve ourselves and our world."
        },
        {
            name: "Sukkot",
            date: "September 30 - October 6, 2023",
            hebrewDate: "15-21 Tishrei 5784",
            year: 2023,
            message: "Sukkot, the Festival of Booths, reminds us of our journey through the wilderness and the transience of life. As we dwell in the sukkah, we express gratitude for Hashem's protection and the abundance He provides."
        },
        {
            name: "Shemini Atzeret",
            date: "October 7, 2023",
            hebrewDate: "22 Tishrei 5784",
            year: 2023,
            message: "Shemini Atzeret is a day of spiritual reflection and prayer for rain, marking the end of the festival season. It is a moment to seek Hashem's blessings for a prosperous and peaceful year."
        },
        {
            name: "Simchat Torah",
            date: "October 8, 2023",
            hebrewDate: "23 Tishrei 5784",
            year: 2023,
            message: "Simchat Torah is a joyful celebration of the completion and renewal of the Torah reading cycle. We rejoice in the Torah's wisdom, dancing and singing in gratitude for its guidance and the light it brings to our lives."
        },
        {
            name: "Hanukkah",
            date: "December 8-15, 2023",
            hebrewDate: "25 Kislev - 2 Tevet 5784",
            year: 2023,
            message: "Hanukkah, the Festival of Lights, celebrates the miracle of the oil and the rededication of the Holy Temple. The menorah's light symbolizes hope, faith, and perseverance, inspiring us to kindle these virtues in our lives."
        }
    ],
    "2024": [
        {
            name: "Tu B'Shevat",
            date: "January 25, 2024",
            hebrewDate: "15 Shevat 5784",
            year: 2024,
            message: "As we observe Tu B'Shevat, we are reminded of our connection to the land of Israel and our responsibility to care for the environment. This day encourages us to reflect on the blessings of nature and our duty to preserve it for future generations."
        },
        {
            name: "Purim",
            date: "March 23-24, 2024",
            hebrewDate: "14-15 Adar II 5784",
            year: 2024,
            message: "Purim is a time of joy and celebration, reminding us of the hidden miracles in our lives. The story of Esther and Mordechai teaches us about courage, faith, and the importance of standing up against injustice."
        },
        {
            name: "Passover",
            date: "April 22-30, 2024",
            hebrewDate: "15-22 Nisan 5784",
            year: 2024,
            message: "Passover is a time to remember the Exodus from Egypt and the importance of freedom. As we gather for the Seder, we reflect on our own journey from slavery to liberation and the enduring strength of our people."
        },
        {
            name: "Yom HaShoah",
            date: "May 6-7, 2024",
            hebrewDate: "27 Nisan 5784",
            year: 2024,
            message: "On Yom HaShoah, we pause to remember the victims of the Holocaust and honor the survivors. This day serves as a powerful reminder of our responsibility to combat antisemitism, promote tolerance, and stand up against injustice wherever it may arise."
        },
        {
            name: "Yom HaZikaron",
            date: "May 13-14, 2024",
            hebrewDate: "4 Iyar 5784",
            year: 2024,
            message: "Yom HaZikaron is a day to honor the brave soldiers who sacrificed their lives for the State of Israel. Their courage and dedication remind us of our own responsibilities to uphold the values of justice and peace."
        },
        {
            name: "Yom HaAtzmaut",
            date: "May 14-15, 2024",
            hebrewDate: "5 Iyar 5784",
            year: 2024,
            message: "Yom HaAtzmaut celebrates the independence of Israel and the resilience of our nation. It is a day to rejoice in our freedom and to commit to building a just and prosperous society for all."
        },
        {
            name: "Lag B'Omer",
            date: "May 26-27, 2024",
            hebrewDate: "18 Iyar 5784",
            year: 2024,
            message: "Lag B'Omer marks the end of a period of mourning and celebrates the life of Rabbi Shimon bar Yochai. It is a time for joy and reflection on the teachings of Torah and Jewish mysticism."
        },
        {
            name: "Shavuot",
            date: "June 11-13, 2024",
            hebrewDate: "6-7 Sivan 5784",
            year: 2024,
            message: "Shavuot commemorates the giving of the Torah at Mount Sinai. It is a time to renew our commitment to Torah study and to living a life guided by its sacred teachings."
        },
        {
            name: "Tisha B'Av",
            date: "August 13-14, 2024",
            hebrewDate: "9 Av 5784",
            year: 2024,
            message: "Tisha B'Av is a day of mourning for the destruction of the Holy Temples and other tragedies in Jewish history. It is a time for reflection, repentance, and the hope for the rebuilding of Jerusalem."
        },
        {
            name: "Rosh Hashanah",
            date: "September 30 - October 2, 2024",
            hebrewDate: "1-2 Tishrei 5785",
            year: 2024,
            message: "Rosh Hashanah is the Jewish New Year, a time of reflection and renewal. As we hear the shofar, we are reminded of our responsibility to improve ourselves and to make positive changes in our lives."
        },
        {
            name: "Yom Kippur",
            date: "October 9-10, 2024",
            hebrewDate: "10 Tishrei 5785",
            year: 2024,
            message: "Yom Kippur is the Day of Atonement, a time for introspection and repentance. We seek forgiveness from those we have wronged and strive to improve ourselves in the eyes of Hashem and our fellow human beings."
        },
        {
            name: "Sukkot",
            date: "October 14-20, 2024",
            hebrewDate: "15-21 Tishrei 5785",
            year: 2024,
            message: "Sukkot, the Feast of Tabernacles, is a time to dwell in temporary shelters, reminding us of the transience of life and the importance of gratitude. We celebrate the harvest and the blessings we have received."
        },
        {
            name: "Shemini Atzeret",
            date: "October 21-22, 2024",
            hebrewDate: "22 Tishrei 5785",
            year: 2024,
            message: "Shemini Atzeret is a day of spiritual reflection and prayer for rain. As we conclude the festival season, we seek blessings of prosperity and peace for the coming year."
        },
        {
            name: "Simchat Torah",
            date: "October 22-23, 2024",
            hebrewDate: "23 Tishrei 5785",
            year: 2024,
            message: "Simchat Torah is a joyous celebration of the completion and renewal of the Torah reading cycle. We dance with the Torah, rejoicing in its wisdom and guidance."
        },
        {
            name: "Hanukkah",
            date: "December 25, 2024 - January 2, 2025",
            hebrewDate: "25 Kislev - 2 Tevet 5785",
            year: 2024,
            message: "Hanukkah, the Festival of Lights, celebrates the miracle of the oil and the rededication of the Holy Temple. The light of the menorah inspires us to kindle the flames of faith, hope, and perseverance in our hearts."
        }
    ],
    "2025": [
        {
            name: "Tu B'Shevat",
            date: "February 13, 2025",
            hebrewDate: "15 Shevat 5785",
            year: 2025,
            message: "As we celebrate Tu B'Shevat, let us marvel at the wonders of creation. This New Year for Trees invites us to recognize the sacredness in nature, our role as stewards of the earth, and our responsibility to nurture its beauty and vitality."
        },
        {
            name: "Purim",
            date: "March 14-15, 2025",
            hebrewDate: "14-15 Adar 5785",
            year: 2025,
            message: "Purim teaches us about the courage to stand against tyranny and the hidden hand of Divine Providence. In the joyous celebration, we are reminded of the resilience of the Jewish spirit and the power of faith and unity in overcoming adversity."
        },
        {
            name: "Passover",
            date: "April 13-20, 2025",
            hebrewDate: "15-22 Nisan 5785",
            year: 2025,
            message: "Passover is a time to relive the journey from slavery to freedom. It is a festival that calls us to reflect on our own liberation, to empathize with the oppressed, and to renew our commitment to justice and human dignity for all."
        },
        {
            name: "Yom HaShoah",
            date: "April 28, 2025",
            hebrewDate: "27 Nisan 5785",
            year: 2025,
            message: "Yom HaShoah is a solemn day to remember the six million Jews who perished in the Holocaust. It is a call to conscience, urging us to bear witness, to remember, and to commit to a world where such evil will never again take root."
        },
        {
            name: "Yom HaZikaron",
            date: "May 5, 2025",
            hebrewDate: "4 Iyar 5785",
            year: 2025,
            message: "On Yom HaZikaron, we honor the memory of those who gave their lives for the State of Israel. Their sacrifice is a testament to the sanctity of life and the depth of commitment to the values of freedom and peace."
        },
        {
            name: "Yom HaAtzmaut",
            date: "May 6, 2025",
            hebrewDate: "5 Iyar 5785",
            year: 2025,
            message: "Yom HaAtzmaut celebrates the miracle of Israel's rebirth. It is a day of gratitude for the courage and vision of those who made independence possible, and a time to reaffirm our dedication to building a just and compassionate society."
        },
        {
            name: "Lag B'Omer",
            date: "May 18, 2025",
            hebrewDate: "18 Iyar 5785",
            year: 2025,
            message: "Lag B'Omer marks a pause in the mourning period of the Omer and celebrates the life and teachings of Rabbi Shimon bar Yochai. It is a day of joy and reflection on the spiritual light that can illuminate our lives and guide us through darkness."
        },
        {
            name: "Shavuot",
            date: "June 2-3, 2025",
            hebrewDate: "6-7 Sivan 5785",
            year: 2025,
            message: "Shavuot commemorates the giving of the Torah at Mount Sinai, a covenant that defines our identity and mission. As we celebrate, let us renew our commitment to the Torah's teachings, embracing its call to holiness, justice, and compassion."
        },
        {
            name: "Tisha B'Av",
            date: "August 3, 2025",
            hebrewDate: "9 Av 5785",
            year: 2025,
            message: "Tisha B'Av is a day of mourning for the destruction of the Holy Temples and other tragedies in Jewish history. It is a time for introspection and unity, fostering a deep yearning for the restoration of Jerusalem and true peace."
        },
        {
            name: "Rosh Hashanah",
            date: "September 20-21, 2025",
            hebrewDate: "1-2 Tishrei 5786",
            year: 2025,
            message: "Rosh Hashanah, the Jewish New Year, is a time of reflection, repentance, and renewal. The shofar's call reminds us to evaluate our lives, seek forgiveness, and commit to growth and betterment in the coming year."
        },
        {
            name: "Yom Kippur",
            date: "September 29, 2025",
            hebrewDate: "10 Tishrei 5786",
            year: 2025,
            message: "As we observe Yom Kippur, we engage in deep self-reflection and spiritual renewal. This sacred day invites us to confront our shortcomings, seek reconciliation with others, and renew our commitment to living a life of integrity and compassion."
        },
        {
            name: "Sukkot",
            date: "October 4-10, 2025",
            hebrewDate: "15-21 Tishrei 5786",
            year: 2025,
            message: "Sukkot, the Festival of Booths, reminds us of our journey through the wilderness and the transience of life. Dwelling in the sukkah, we express gratitude for Hashem's protection and the bounty He provides."
        },
        {
            name: "Shemini Atzeret",
            date: "October 11, 2025",
            hebrewDate: "22 Tishrei 5786",
            year: 2025,
            message: "Shemini Atzeret marks a moment of intimate connection with Hashem as we conclude the festival season. It's a time to internalize the spiritual gains of the preceding holidays and pray for divine blessings of sustenance and harmony in the year ahead."
        },
        {
            name: "Simchat Torah",
            date: "October 12, 2025",
            hebrewDate: "23 Tishrei 5786",
            year: 2025,
            message: "On Simchat Torah, we celebrate the eternal cycle of Torah study with unbridled joy. This festival reminds us that the Torah is a living document, constantly offering new insights and guidance as we dance with its scrolls and renew our dedication to its teachings."
        },
        {
            name: "Hanukkah",
            date: "December 14-22, 2025",
            hebrewDate: "25 Kislev - 3 Tevet 5786",
            year: 2025,
            message: "Hanukkah, the Festival of Lights, commemorates the rededication of the Holy Temple and the miracle of the oil. The menorah's light inspires us to kindle the flames of faith, hope, and perseverance in our hearts."
        }
    ],
    "2026": [
        {
            name: "Tu B'Shevat",
            date: "January 26, 2026",
            hebrewDate: "15 Shevat 5786",
            year: 2026,
            message: "As we celebrate Tu B'Shevat, the New Year for Trees, let us reflect on the sanctity of nature and our duty to protect it. This day reminds us of our spiritual connection to the land and the importance of environmental stewardship."
        },
        {
            name: "Purim",
            date: "March 1-2, 2026",
            hebrewDate: "14-15 Adar 5786",
            year: 2026,
            message: "Purim is a festival of joy and hidden miracles. The story of Esther teaches us about courage and faith in the face of adversity. Let us celebrate with joy, charity, and unity, recognizing the unseen hand of Hashem in our lives."
        },
        {
            name: "Passover",
            date: "April 1-9, 2026",
            hebrewDate: "15-22 Nisan 5786",
            year: 2026,
            message: "Passover commemorates the Exodus from Egypt, a journey from slavery to freedom. As we gather for the Seder, we remember the importance of liberation and the enduring strength of the Jewish spirit. Let us cherish our freedom and strive for justice."
        },
        {
            name: "Yom HaShoah",
            date: "April 21, 2026",
            hebrewDate: "27 Nisan 5786",
            year: 2026,
            message: "Yom HaShoah calls us to reflect on the darkest chapter of our history and reaffirm our commitment to 'Never Again.' It is a day to educate future generations about the Holocaust, celebrate the resilience of survivors, and work towards a world free from hatred and prejudice."
        },
        {
            name: "Yom HaZikaron",
            date: "April 28, 2026",
            hebrewDate: "4 Iyar 5786",
            year: 2026,
            message: "On Yom HaZikaron, we honor the brave soldiers who sacrificed their lives for Israel. Their dedication and bravery inspire us to uphold the values of justice and peace, and to cherish the freedom they secured for us."
        },
        {
            name: "Yom HaAtzmaut",
            date: "April 29, 2026",
            hebrewDate: "5 Iyar 5786",
            year: 2026,
            message: "Yom HaAtzmaut celebrates the independence and achievements of the State of Israel. It is a day to rejoice in our nation's resilience and to renew our commitment to building a just and prosperous society for all."
        },
        {
            name: "Lag B'Omer",
            date: "May 10, 2026",
            hebrewDate: "18 Iyar 5786",
            year: 2026,
            message: "Lag B'Omer marks a joyous break in the Omer period and celebrates the life of Rabbi Shimon bar Yochai. It is a time for festivities, bonfires, and reflection on the spiritual teachings that illuminate our lives."
        },
        {
            name: "Shavuot",
            date: "May 21-22, 2026",
            hebrewDate: "6-7 Sivan 5786",
            year: 2026,
            message: "Shavuot commemorates the giving of the Torah at Mount Sinai. As we celebrate this sacred event, we renew our commitment to Torah study and to living by its divine teachings, which guide us in our daily lives."
        },
        {
            name: "Tisha B'Av",
            date: "August 1, 2026",
            hebrewDate: "9 Av 5786",
            year: 2026,
            message: "Tisha B'Av is a day of mourning for the destruction of the Holy Temples and other tragedies in Jewish history. It is a time for reflection, repentance, and the hope for the rebuilding of Jerusalem and the restoration of peace."
        },
        {
            name: "Rosh Hashanah",
            date: "September 26-27, 2026",
            hebrewDate: "1-2 Tishrei 5787",
            year: 2026,
            message: "Rosh Hashanah, the Jewish New Year, is a time of reflection and renewal. As we hear the shofar's call, we are reminded to evaluate our lives, seek forgiveness, and commit to personal growth and positive change in the coming year."
        },
        {
            name: "Yom Kippur",
            date: "October 5, 2026",
            hebrewDate: "10 Tishrei 5787",
            year: 2026,
            message: "Yom Kippur, the Day of Atonement, is a day for deep introspection and spiritual renewal. Through fasting, prayer, and repentance, we seek to reconcile with Hashem and our fellow human beings, striving for a life of righteousness."
        },
        {
            name: "Sukkot",
            date: "October 10-16, 2026",
            hebrewDate: "15-21 Tishrei 5787",
            year: 2026,
            message: "Sukkot, the Festival of Booths, reminds us of our journey through the wilderness and the transience of life. Dwelling in the sukkah, we express gratitude for Hashem's protection and the abundance He provides."
        },
        {
            name: "Shemini Atzeret",
            date: "October 17, 2026",
            hebrewDate: "22 Tishrei 5787",
            year: 2026,
            message: "Shemini Atzeret is a day of spiritual reflection and prayer for rain. It marks the conclusion of the festival season, a time to seek Hashem's blessings for prosperity and peace in the coming year."
        },
        {
            name: "Simchat Torah",
            date: "October 18, 2026",
            hebrewDate: "23 Tishrei 5787",
            year: 2026,
            message: "Simchat Torah is a joyful celebration of the completion and renewal of the Torah reading cycle. We rejoice in the Torah's wisdom, dancing and singing in gratitude for its guidance and the light it brings to our lives."
        },
        {
            name: "Hanukkah",
            date: "December 15-23, 2026",
            hebrewDate: "25 Kislev - 3 Tevet 5787",
            year: 2026,
            message: "Hanukkah, the Festival of Lights, commemorates the miracle of the oil and the rededication of the Holy Temple. The menorah's light symbolizes hope, faith, and perseverance, inspiring us to kindle these virtues in our lives."
        }
    ]
};
