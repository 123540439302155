import React from 'react';
import { Helmet } from 'react-helmet';
import { ArrowRightLeft, ArrowRight, Calendar } from 'lucide-react';
import Guide from './Guide';
import FAQ from './FAQ';
import { HDate } from '@hebcal/core';

const Home = ({
  conversionType,
  handleConversionTypeChange,
  gregorianYearRef,
  gregorianMonthRef,
  gregorianDayRef,
  hebrewYearRef,
  hebrewMonthRef,
  hebrewDayRef,
  handleConvert,
  result,
  isError
}) => {
  const handleToday = () => {
    const today = new Date();
    if (conversionType === 'gregorianToHebrew') {
      gregorianYearRef.current.value = today.getFullYear().toString();
      gregorianMonthRef.current.value = (today.getMonth() + 1).toString().padStart(2, '0');
      gregorianDayRef.current.value = today.getDate().toString().padStart(2, '0');
    } else {
      const hebrewDate = new HDate();
      hebrewYearRef.current.value = hebrewDate.getFullYear().toString();
      // Convert Hebrew month name to English
      const hebrewMonthName = hebrewDate.getMonthName();
      const englishMonthNames = {
        'Nisan': 'Nisan',
        'Iyyar': 'Iyar',
        'Sivan': 'Sivan',
        'Tamuz': 'Tammuz',
        'Av': 'Av',
        'Elul': 'Elul',
        'Tishrei': 'Tishrei',
        'Cheshvan': 'Cheshvan',
        'Kislev': 'Kislev',
        'Tevet': 'Tevet',
        'Sh\'vat': 'Shevat',
        'Adar': 'Adar',
        'Adar I': 'Adar I',
        'Adar II': 'Adar II'
      };
      hebrewMonthRef.current.value = englishMonthNames[hebrewMonthName] || hebrewMonthName;
      hebrewDayRef.current.value = hebrewDate.getDate().toString();
    }
  };

  return (
    <main className="container mx-auto px-4 py-8 md:py-16">
      <Helmet>
        <title>Hebrew Calendar Converter - Home</title>
        <meta name="description" content="Convert dates between Hebrew and Gregorian calendars with AI assistance. Perfect for converting conventions, birthdays, and today's dates for both Jewish and Christian contexts." />
        <meta name="keywords" content="Hebrew Calendar, Gregorian Calendar, Date Converter, Calendar Conversion, Jewish Calendar, Christian Calendar, AI Date Conversion, 2024, 2025, Birthdays, Conventions, Today" />
      </Helmet>
      <h1 className="text-3xl md:text-5xl font-bold text-center mb-2">
        Hebrew-Gregorian <span className="text-blue-600">Calendar</span>
      </h1>
      <h1 className="text-3xl md:text-5xl font-bold text-center mb-4 md:mb-8">
        Converter
      </h1>
      
      <p className="text-center text-lg md:text-xl mb-8 md:mb-12 text-gray-600">
        Switch between Hebrew and Gregorian calendars with ease. Now with AI-powered text conversion!
      </p>

      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-4 md:p-8 mb-8">
        <div className="flex justify-center mb-6 md:mb-8">
          <button
            onClick={() => handleConversionTypeChange('gregorianToHebrew')}
            className={`px-3 md:px-4 py-2 text-sm md:text-base rounded-l-md ${conversionType === 'gregorianToHebrew' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Gregorian to Hebrew
          </button>
          <button
            onClick={() => handleConversionTypeChange('hebrewToGregorian')}
            className={`px-3 md:px-4 py-2 text-sm md:text-base rounded-r-md ${conversionType === 'hebrewToGregorian' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Hebrew to Gregorian
          </button>
        </div>

        <div className="grid grid-cols-1 gap-4 md:gap-8">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {conversionType === 'gregorianToHebrew' ? 'Gregorian Date:' : 'Hebrew Date:'}
            </label>
            {conversionType === 'gregorianToHebrew' ? (
              <div className="grid grid-cols-3 gap-2">
                <input
                  type="number"
                  placeholder="Year"
                  className="p-2.5 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="2024"
                  ref={gregorianYearRef}
                />
                <select
                  className="p-2.5 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="07"
                  ref={gregorianMonthRef}
                >
                  {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
                <input
                  type="number"
                  placeholder="Day"
                  className="p-2.5 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="04"
                  ref={gregorianDayRef}
                />
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-2">
                <input
                  type="number"
                  placeholder="Year"
                  className="p-2.5 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="5784"
                  ref={hebrewYearRef}
                />
                <select
                  className="p-2.5 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="Sivan"
                  ref={hebrewMonthRef}
                >
                  {['Nisan', 'Iyar', 'Sivan', 'Tammuz', 'Av', 'Elul', 'Tishrei', 'Cheshvan', 'Kislev', 'Tevet', 'Shevat', 'Adar', 'Adar I', 'Adar II'].map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
                <input
                  type="number"
                  placeholder="Day"
                  className="p-2.5 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  defaultValue="28"
                  ref={hebrewDayRef}
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-center">
            <ArrowRight className="w-6 h-6 md:w-8 md:h-8 text-blue-600 transform rotate-90 md:rotate-0" />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {conversionType === 'gregorianToHebrew' ? 'Hebrew Date:' : 'Gregorian Date:'}
            </label>
            <input
              type="text"
              className={`w-full p-2.5 border border-gray-300 rounded-md bg-gray-100 text-center ${isError ? 'text-red-600' : 'text-black'}`}
              readOnly
              value={result}
              style={{ fontWeight: 'bold' }}
            />
          </div>
        </div>
        <button
          onClick={handleConvert}
          className="w-full mt-6 md:mt-8 bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <ArrowRightLeft className="mr-2 h-5 w-5" />
          <span>Convert</span>
        </button>
        <button
          onClick={handleToday}
          className="w-full mt-3 md:mt-5 bg-gray-600 text-white py-3 px-4 rounded-md hover:bg-gray-700 transition duration-300 flex items-center justify-center"
        >
          <Calendar className="mr-2 h-5 w-5" />
          <span>Today</span>
        </button>
      </div>

      <Guide />
      <FAQ />
    </main>
  );
};

export default Home;

