import React, { useState, useRef, useEffect } from 'react';
import { ArrowRightLeft, HelpCircle } from 'lucide-react';
import { Helmet } from 'react-helmet';

const HebrewWithAI = () => {
  const [input, setInput] = useState('');
  const [result, setResult] = useState('');
  const [conversionType, setConversionType] = useState('gregorianToHebrew');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showExample, setShowExample] = useState(false);
  const textareaRef = useRef(null);

  const gregorianExample = "The project starts on July 1, 2023, has a midpoint review on August 15, 2023, and ends on September 30, 2023.";
  const hebrewExample = "The event is scheduled for 15 Av, 5783. It coincides with the conclusion of the Shiva Asar B'Tammuz fast on 17 Tammuz, 5783.";

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.max(textarea.scrollHeight, 150)}px`;
    }
  };

  const handleConvert = async () => {
    setIsRefreshing(true);
    try {
      const response = await fetch(
        conversionType === 'gregorianToHebrew' ? '/api/gregorian_to_hebrew' : '/api/hebrew_to_gregorian',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ content: input }),
        }
      );

      const data = await response.json();

      if (data.error) {
        setResult(`Error: ${data.error}`);
      } else if (data.converted_content) {
        setResult(data.converted_content);
      } else {
        setResult('Unexpected response from server');
      }
    } catch (error) {
      setResult(`Error: ${error.message}`);
    } finally {
      setTimeout(() => setIsRefreshing(false), 300);
    }
  };

  const handleConversionTypeChange = (type) => {
    setConversionType(type);
    setInput('');
    setResult('');
  };

  const toggleExample = () => {
    setShowExample(!showExample);
  };

  return (
    <div className="container mx-auto px-4 py-8 md:py-16">
      <Helmet>
        <title>Hebrew with AI - Gregorian to Hebrew Date Conversion</title>
        <meta name="description" content="Convert dates between Gregorian and Hebrew calendars using AI. Quick and accurate date conversion for all your needs." />
        <meta name="keywords" content="Hebrew date conversion, Gregorian to Hebrew, Hebrew to Gregorian, date converter, AI date conversion, Hebrew calendar, Gregorian calendar, convert dates, AI calendar tool" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SoftwareApplication",
              "name": "Hebrew with AI",
              "applicationCategory": "UtilityApplication",
              "operatingSystem": "Any",
              "offers": {
                "@type": "Offer",
                "price": "0",
                "priceCurrency": "USD"
              }
            }
          `}
        </script>
      </Helmet>
      <h1 className="text-3xl md:text-5xl font-bold text-center mb-8">Hebrew with AI</h1>
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
        <div className="flex justify-center mb-6 md:mb-8">
          <button
            onClick={() => handleConversionTypeChange('gregorianToHebrew')}
            className={`px-3 md:px-4 py-2 text-sm md:text-base rounded-l-md ${
              conversionType === 'gregorianToHebrew' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
            }`}
          >
            Gregorian to Hebrew
          </button>
          <button
            onClick={() => handleConversionTypeChange('hebrewToGregorian')}
            className={`px-3 md:px-4 py-2 text-sm md:text-base rounded-r-md ${
              conversionType === 'hebrewToGregorian' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'
            }`}
          >
            Hebrew to Gregorian
          </button>
        </div>
        <div className="mb-4 bg-gray-50 rounded-lg p-4 border border-gray-200">
          <div className="relative mb-2">
            <textarea
              ref={textareaRef}
              className="w-full p-3 border border-gray-300 rounded-md resize-none overflow-hidden bg-white"
              style={{ minHeight: '150px' }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter text with dates..."
            />
            <button
              onClick={toggleExample}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              title="Show/Hide Example"
            >
              <HelpCircle size={20} />
            </button>
          </div>
          {showExample && (
            <div className="mt-2 p-3 bg-white rounded-md text-sm text-gray-600 border border-gray-200">
              <p className="font-semibold mb-1">Example:</p>
              <p>{conversionType === 'gregorianToHebrew' ? gregorianExample : hebrewExample}</p>
            </div>
          )}
        </div>
        <button
          onClick={handleConvert}
          className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center"
        >
          <ArrowRightLeft className="mr-2 h-5 w-5" />
          <span>Convert</span>
        </button>
        {result && (
          <div className={`mt-6 p-4 bg-gray-100 rounded-md transition-all duration-300 ${isRefreshing ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`}>
            <h3 className="font-bold mb-2">Result:</h3>
            <p>{result}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HebrewWithAI;

