import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Calendar, Menu, X, Sparkles, ChevronDown } from 'lucide-react';
import { yearlyHolidayData } from '../yearlyHolidayData'; // Import yearly holiday data using correct path

const Header = ({ mobileMenuOpen, setMobileMenuOpen }) => {
  const [showAIText, setShowAIText] = useState(true);
  const location = useLocation();
  const [utilityDropdownOpen, setUtilityDropdownOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAIText(false);
    }, 5000); // Show AI text for 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setUtilityDropdownOpen(false);
  };

  // Get supported years from yearlyHolidayData
  const supportedYears = Object.keys(yearlyHolidayData);

  return (
    <header className="bg-gray-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center space-x-2">
          <Calendar className="w-8 h-8 text-blue-600" />
          <span className="text-xl font-bold">Calendar Converter</span>
        </Link>
        <div className="flex items-center space-x-4">
          <nav className="hidden md:block">
            <ul className="flex space-x-6 text-gray-700 text-sm items-center">
              <li><Link to="/" className={`hover:text-blue-600 ${location.pathname === '/' ? 'text-blue-600' : ''}`}>Home</Link></li>
              <li>
                <Link 
                  to="/hebrew-with-ai" 
                  className={`hover:text-blue-600 bg-gradient-to-r from-purple-400 to-pink-500 text-white px-3 py-1 rounded-full flex items-center transition-all duration-300 hover:shadow-lg hover:scale-105 ${location.pathname === '/hebrew-with-ai' ? 'shadow-lg scale-105' : ''}`}
                >
                  <Sparkles className="w-4 h-4 mr-1" />
                  Hebrew with AI
                </Link>
              </li>
              <li className="relative group">
                <button
                  className={`flex items-center hover:text-blue-600 ${utilityDropdownOpen ? 'text-blue-600' : ''}`}
                  onClick={() => setUtilityDropdownOpen(!utilityDropdownOpen)}
                >
                  Utilities
                  <ChevronDown className="w-4 h-4 ml-1" />
                </button>
                <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-48 bg-white rounded-md shadow-lg z-10 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                  {supportedYears.map(year => (
                    <Link key={year} to={`/jewish-holidays-${year}`} className="block px-4 py-2 text-sm text-center text-gray-700 hover:bg-blue-500 hover:text-white">
                      Jewish Holidays {year}
                    </Link>
                  ))}
                  {/* Add more utility links here as needed */}
                </div>
              </li>
              <li><Link to="/guide" className={`hover:text-blue-600 ${location.pathname === '/guide' ? 'text-blue-600' : ''}`}>Guide</Link></li>
              <li><Link to="/faq" className={`hover:text-blue-600 ${location.pathname === '/faq' ? 'text-blue-600' : ''}`}>FAQ</Link></li>
              <li><Link to="/about" className={`hover:text-blue-600 ${location.pathname === '/about' ? 'text-blue-600' : ''}`}>About</Link></li>
            </ul>
          </nav>
          <div className="md:hidden">
            {showAIText ? (
              <Link 
                to="/hebrew-with-ai"
                className="animate-pulse bg-gradient-to-r from-purple-400 to-pink-500 text-white px-3 py-1 rounded-full flex items-center"
              >
                <Sparkles className="w-4 h-4 mr-1" />
                AI
              </Link>
            ) : (
              <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            )}
          </div>
        </div>
      </div>
      {mobileMenuOpen && (
        <nav className="md:hidden">
          <ul className="flex flex-col items-center space-y-4 py-4 text-gray-700 text-sm">
            <li><Link to="/" onClick={closeMobileMenu} className={`hover:text-blue-600 ${location.pathname === '/' ? 'text-blue-600' : ''}`}>Home</Link></li>
            <li>
              <Link 
                to="/hebrew-with-ai" 
                onClick={closeMobileMenu}
                className={`hover:text-blue-600 bg-gradient-to-r from-purple-400 to-pink-500 text-white px-3 py-1 rounded-full flex items-center ${location.pathname === '/hebrew-with-ai' ? 'shadow-lg scale-105' : ''}`}
              >
                <Sparkles className="w-4 h-4 mr-1" />
                Hebrew with AI
              </Link>
            </li>
            <li className="w-full text-center">
              <button
                className="hover:text-blue-600 flex items-center justify-center w-full"
                onClick={() => setUtilityDropdownOpen(!utilityDropdownOpen)}
              >
                Utilities
                <ChevronDown className={`w-4 h-4 ml-1 transform transition-transform ${utilityDropdownOpen ? 'rotate-180' : ''}`} />
              </button>
              {utilityDropdownOpen && (
                <ul className="mt-2 space-y-2 w-full">
                  {supportedYears.map(year => (
                    <li key={year}><Link to={`/jewish-holidays-${year}`} onClick={closeMobileMenu} className="block hover:text-blue-600 text-center">Jewish Holidays {year}</Link></li>
                  ))}
                  {/* Add more utility links here as needed */}
                </ul>
              )}
            </li>
            <li><Link to="/guide" onClick={closeMobileMenu} className={`hover:text-blue-600 ${location.pathname === '/guide' ? 'text-blue-600' : ''}`}>Guide</Link></li>
            <li><Link to="/faq" onClick={closeMobileMenu} className={`hover:text-blue-600 ${location.pathname === '/faq' ? 'text-blue-600' : ''}`}>FAQ</Link></li>
            <li><Link to="/about" onClick={closeMobileMenu} className={`hover:text-blue-600 ${location.pathname === '/about' ? 'text-blue-600' : ''}`}>About</Link></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;

