import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-gray-50 text-gray-700 py-6 mt-12">
    <div className="container mx-auto px-4 flex flex-col items-center">
      <div className="flex space-x-6 mb-8 text-gray-700 text-sm">
        <Link to="/" className="hover:text-blue-600">Home</Link>
        <Link to="/hebrew-with-ai" className="hover:text-blue-600">Hebrew with AI</Link>
        <Link to="/guide" className="hover:text-blue-600">Guide</Link>
        <Link to="/faq" className="hover:text-blue-600">FAQ</Link>
        <Link to="/about" className="hover:text-blue-600">About</Link>
      </div>
      <div className="w-full border-t border-gray-300 mb-8"></div>
      <div className="flex justify-between items-center w-full mt-4">
        <div className="text-sm">
          &copy; 2024 Huisong Li. All rights reserved.
        </div>
        <div className="flex space-x-4">
          <a href="https://x.com/HuisongLi" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1668.56 1221.19" className="fill-current">
              <path d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
              h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"/>
            </svg>
          </a>
          <a href="https://www.linkedin.com/in/lihuisong/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" className="fill-current">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
