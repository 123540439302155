import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 md:p-6 mb-4 md:mb-6">
      <button
        className="flex justify-between items-center w-full text-left text-base md:text-lg font-semibold mb-2"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="pr-4">{question}</span>
        {isExpanded ? <Minus className="h-5 w-5 flex-shrink-0" /> : <Plus className="h-5 w-5 flex-shrink-0" />}
      </button>
      {isExpanded && <div className="mt-2 text-sm md:text-base">{answer}</div>}
    </div>
  );
};

const FAQ = () => (
  <section className="container mx-auto px-4 py-8 md:py-16">
    <h2 className="text-xl md:text-3xl font-bold text-center mb-8">
      Frequently Asked <span className="text-blue-600">Questions</span>
    </h2>
    <div className="max-w-3xl mx-auto">
      <FAQItem 
        question="What is the year range limitation for Hebrew calendar conversions?"
        answer={
          <div>
            <p>Our Hebrew calendar conversion supports a wide range of years:</p>
            <ul className="list-disc list-inside mt-2">
              <li>The earliest supported Hebrew year is 1 (3761 BCE in the Gregorian calendar).</li>
              <li>The latest supported Hebrew year is 9999 (6239 CE in the Gregorian calendar).</li>
            </ul>
            <p className="mt-2">This range covers the entire span of the Hebrew calendar, from its traditional beginning to the far future, allowing for comprehensive historical and futuristic date conversions.</p>
          </div>
        }
      />
      <FAQItem 
        question="What is the year range limitation for Gregorian calendar conversions?"
        answer={
          <div>
            <p>Our Gregorian calendar conversion supports a broad range of years:</p>
            <ul className="list-disc list-inside mt-2">
              <li>The earliest supported Gregorian year is 1 (3761 in the Hebrew calendar).</li>
              <li>The latest supported Gregorian year is 9999.</li>
            </ul>
            <p className="mt-2">This range allows for conversions spanning from the beginning of the Common Era to the far future. Note that for dates before 1582 (when the Gregorian calendar was adopted), the conversion uses the proleptic Gregorian calendar.</p>
          </div>
        }
      />
      <FAQItem 
        question="Why do Hebrew and Gregorian dates not always match up?"
        answer={
          <div>
            <p>The Hebrew calendar is lunisolar, while the Gregorian is solar. This leads to differences in how days and months are calculated. Key reasons include:</p>
            <ul className="list-disc list-inside mt-2">
              <li>The Hebrew calendar's months are based on the lunar cycle, while the Gregorian calendar's months are fixed.</li>
              <li>The Hebrew calendar adds an extra month (Adar II) in leap years to align with the solar year.</li>
              <li>The Hebrew day starts at sunset, not midnight like in the Gregorian calendar.</li>
            </ul>
          </div>
        }
      />
      <FAQItem 
        question="How accurate is this converter?"
        answer={
          <div>
            <p>Our converter uses precise algorithms to ensure high accuracy in date conversions between the two calendar systems. It takes into account:</p>
            <ul className="list-disc list-inside mt-2">
              <li>Leap years in both calendar systems</li>
              <li>The complex rules of the Hebrew calendar</li>
              <li>Historical changes in calendar systems</li>
            </ul>
            <p className="mt-2">While it's highly accurate, for legal or religious purposes, always consult with a recognized authority.</p>
          </div>
        }
      />
      <FAQItem 
        question="What is the Hebrew with AI feature?"
        answer={
          <div>
            <p>Hebrew with AI is an advanced feature that uses large language models (LLMs) to identify and convert dates within text, supporting multiple languages and date formats. It can:</p>
            <ul className="list-disc list-inside mt-2">
              <li>Recognize dates in various formats and languages</li>
              <li>Convert between Hebrew and Gregorian dates in context</li>
              <li>Handle multiple dates within the same text</li>
              <li>Preserve the original text structure while replacing dates</li>
              <li>Understand and process complex historical and cultural date references</li>
            </ul>
            <p className="mt-2">This feature is particularly useful for converting dates in documents, emails, or any text-based content, leveraging the power of AI to enhance accuracy and context understanding.</p>
          </div>
        }
      />
    </div>
  </section>
);

export default FAQ;
