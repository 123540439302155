import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Home } from 'lucide-react';

const NotFound = () => {
  const [quote, setQuote] = useState('');

  const quotes = useMemo(() => [
    {
      text: "Peace is the greatest blessing of our life.",
      source: "Mishnah Uktzin 3:12"
    },
    {
      text: "Better a dry crust of bread with peace of mind than a mansion filled with discord.",
      source: "Based on Proverbs 17:1"
    },
    {
      text: "He who makes peace in his own home receives the reward as if he had brought peace to the whole world.",
      source: "Talmud, Sanhedrin 99b"
    },
    {
      text: "Great is peace, for God's name is peace.",
      source: "Midrash Leviticus Rabbah 9:9"
    },
    {
      text: "When there is peace in the house, there is peace in the world.",
      source: "Yiddish Proverb"
    }
  ], []);

  useEffect(() => {
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
  }, [quotes]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-2">404</h1>
        <h2 className="text-xl font-semibold text-gray-600 mb-4">Page Not Found</h2>
        <p className="text-gray-500 mb-6">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <div className="bg-blue-50 rounded-lg p-4 mb-6">
          <p className="text-sm text-gray-700 italic">"{quote.text}"</p>
          <p className="text-xs text-gray-500 mt-2">— {quote.source}</p>
        </div>
        <Link 
          to="/" 
          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition duration-150 ease-in-out"
        >
          <Home size={16} className="mr-2" />
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;

