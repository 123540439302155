import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import { yearlyHolidayData } from '../yearlyHolidayData'; // Import yearly holiday data using correct path
import { baseHolidayData } from '../baseHolidayData'; // Import base holiday data using correct path

const holidays = [
  "Tu B'Shevat", "Purim", "Passover", "Yom HaShoah", "Yom HaZikaron",
  "Yom HaAtzmaut", "Lag B'Omer", "Shavuot", "Tisha B'Av", "Rosh Hashanah",
  "Yom Kippur", "Sukkot", "Shemini Atzeret", "Simchat Torah", "Hanukkah"
];

const generateAllHolidays = (years) => {
  return years.flatMap(year => holidays.map(name => ({ name, year })));
};

const HolidayPage = ({ holidayName, year, supportedYears }) => {
  const holidayData = yearlyHolidayData[year].find(h => h.name === holidayName);
  const baseData = baseHolidayData.find(bh => bh.name === holidayName);
  const holiday = { ...holidayData, ...baseData };

  const allHolidays = generateAllHolidays(supportedYears);
  const currentIndex = allHolidays.findIndex(h => h.name === holiday.name && h.year === year);
  const prevHoliday = currentIndex > 0 ? allHolidays[currentIndex - 1] : null;
  const nextHoliday = currentIndex < allHolidays.length - 1 ? allHolidays[currentIndex + 1] : null;

  const currentYearIndex = supportedYears.indexOf(year);
  const prevYear = currentYearIndex > 0 ? supportedYears[currentYearIndex - 1] : null;
  const nextYear = currentYearIndex < supportedYears.length - 1 ? supportedYears[currentYearIndex + 1] : null;

  return (
    <>
      <Helmet>
        <title>{`${holiday.name} ${year} - Jewish Holiday | Hebrew Calendar`}</title>
        <meta name="description" content={`Learn about ${holiday.name} ${year}: its history, religious significance, traditions, customs, and spiritual meaning in the Jewish faith.`} />
        <meta name="keywords" content={`Jewish holidays, ${holiday.name}, ${holiday.name} ${year}, Hebrew Calendar, Jewish traditions, Jewish customs`} />
        <link rel="canonical" href={`https://hebrew-calendar.com/holiday/${holiday.name.toLowerCase().replace(/\s+/g, '-')}-${year}`} />
        <style>{`
          .vertical-text {
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            white-space: nowrap;
          }
          @media (max-width: 768px) {
            .vertical-text {
              writing-mode: horizontal-tb;
              transform: none;
            }
          }
        `}</style>
      </Helmet>
      <article className="container mx-auto px-4 py-8 md:py-16 max-w-4xl">
        <header className="mb-8 flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex-shrink-0">
            <h1 className="text-4xl md:text-6xl font-bold whitespace-nowrap">{holiday.name}</h1>
            <p className="text-2xl md:text-3xl text-gray-600">{year}</p>
          </div>
          <nav className="flex flex-col space-y-2 mt-4 md:mt-0 text-sm">
            <div className="flex justify-end items-center space-x-4">
              <span className="text-gray-500">Year:</span>
              {prevYear && (
                <Link to={`/holiday/${holiday.name.toLowerCase().replace(/\s+/g, '-')}-${prevYear}`} className="text-blue-600 hover:text-blue-800 flex items-center">
                  <ChevronLeft className="inline w-4 h-4 mr-1" />
                  {prevYear}
                </Link>
              )}
              {nextYear && (
                <Link to={`/holiday/${holiday.name.toLowerCase().replace(/\s+/g, '-')}-${nextYear}`} className="text-blue-600 hover:text-blue-800 flex items-center">
                  {nextYear}
                  <ChevronRight className="inline w-4 h-4 ml-1" />
                </Link>
              )}
            </div>
            <div className="flex justify-end items-center space-x-4">
              <span className="text-gray-500">Holiday:</span>
              {prevHoliday && (
                <Link to={`/holiday/${prevHoliday.name.toLowerCase().replace(/\s+/g, '-')}-${prevHoliday.year}`} className="text-blue-600 hover:text-blue-800 flex items-center">
                  <ArrowLeft className="inline w-4 h-4 mr-1" />
                  {prevHoliday.name}
                </Link>
              )}
              {nextHoliday && (
                <Link to={`/holiday/${nextHoliday.name.toLowerCase().replace(/\s+/g, '-')}-${nextHoliday.year}`} className="text-blue-600 hover:text-blue-800 flex items-center">
                  {nextHoliday.name}
                  <ArrowRight className="inline w-4 h-4 ml-1" />
                </Link>
              )}
            </div>
          </nav>
        </header>

        <div className="bg-white rounded-lg shadow-md p-6 md:p-8 mb-8">
          <div className="bg-gray-100 rounded-lg p-4 mb-6">
            <p className="italic text-gray-600 text-sm md:text-base">"{holiday.message}"</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">DATE</h2>
            </div>
            <div className="md:col-span-7">
              <p>
                Gregorian: {holiday.date}
                <br />
                Hebrew: {holiday.hebrewDate}
                <br />
                <span className="text-sm text-gray-600">
                  (Observance begins at sunset on the previous day)
                </span>
              </p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">HISTORY</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.history}</p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">SIGNIFICANCE</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.religiousSignificance}</p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">TRADITIONS</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.culturalFamily}</p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">FOOD</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.foodCustoms}</p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">EDUCATION</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.educationalImportance}</p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">SOCIAL</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.socialInteraction}</p>
            </div>

            <div className="md:col-span-1 mb-2 md:mb-0 flex items-end">
              <h2 className="vertical-text text-sm uppercase tracking-wider text-gray-500">SPIRITUAL</h2>
            </div>
            <div className="md:col-span-7">
              <p>{holiday.spiritualReflection}</p>
            </div>
          </div>
        </div>

        <nav className="text-center mb-8">
          <Link to={`/jewish-holidays-${year}`} className="inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
            Back to {year} Holidays
          </Link>
        </nav>
      </article>
    </>
  );
};

export default HolidayPage;

